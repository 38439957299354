import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from '../services/axios';
import './Dashboard.css';

export default function Dashboard(props) {
    const [data, setData] = useState([]);
    const [supervisorId,setSelectedSupervisorId] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setLoading] = React.useState(true);

    useEffect(() => {
        const getData = async () => {
            const { data } = await axios.get(`Ninebox/GetSupervisors`);
            await data.sort((a,b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0));
            for (var i = 0; i < data.length; i++) {
                data[i].firstName = formatName(data[i].firstName);
                data[i].lastName = formatName(data[i].lastName);
            }
            setData(data);
            setSelectedSupervisorId(data[0].employeeId);
        };

        getData();
        setLoading(false);
    }, []);

    const formatName = (name) => {
        const nameInLower = name.toLowerCase();
        return nameInLower.charAt(0).toUpperCase() + nameInLower.slice(1);
    }

    const handleChange = (event) => {
        const selectedId = event.target.value;
        setSelectedSupervisorId(selectedId);

        // If parent provided a callback for admin status changes
        if (props.onSelfViewChange) {
            const isViewingSelf = Number(selectedId) === props.loggedOnUserEmployeeId;
            props.onSelfViewChange(isViewingSelf);
        }
    }

    const fetchAndCheckVisibilityPermissions = async (loggedInUser, selectedSupervisor) => {
        try {
            const response = await axios.get(`Ninebox/GetVisibilityPermissions`);
            const selectedSupervisorVisibilityPermissions = response.data.filter(item => item.supervisorId === Number(selectedSupervisor));
            const isUserAllowedToView = selectedSupervisorVisibilityPermissions.some(item =>
                Number(item.supervisorIdVisibleTo) === Number(loggedInUser)
            );
            return isUserAllowedToView;
        } catch (e) {
            console.error('Error in permission check:', e);
            alert(e);
            return false;
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (await checkPermissions(props.loggedOnUserEmployeeId, supervisorId)) {
            navigate('/Ninebox', {replace: true, state: {supervisorId: supervisorId}});
        } else {
            console.log("NOT ALLOWED TO VIEW THIS PAGE");
            alert("NOT ALLOWED TO VIEW THIS PAGE");
        }
    }

    const checkPermissions = async (loggedInUser, selectedSupervisor) => {
        let isSupervisorVisible = await fetchAndCheckVisibilityPermissions(loggedInUser, selectedSupervisor);

        if (props.isAdmin || isSupervisorVisible) {
            return true;
        } else {
            return false;
        }
    }

    return(
        <div className="Dashboard">
            {!isLoading ?
                <>
                <form className="form" onSubmit={(event) => handleSubmit(event)} >
                    <label>
                        <h3>Please select a Manager</h3>
                        <select  className="selectionList" onChange={handleChange}>
                            {data.map((option) => (
                                <option value={option.employeeId} key={option.employeeId}>{option.firstName} {option.lastName}</option>
                            ))}
                        </select>
                        <br></br>
                        <br></br>
                    </label>
                        <input className="submitButtonPrimary" type="submit" value="Submit"/>
                </form>
                </> : <h2>Loading....</h2>
            }
        </div>
    );
}